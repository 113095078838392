import React from 'react';
import connect from '@vkontakte/vkui-connect';
import bridge from '@vkontakte/vk-bridge';

class App extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			show_text: 0,
			isLoaded: false,
		};
	}

	componentDidMount() {

	}
	

	
	subscribe = () => {
	
		
	}
  
	componentDidMount() {
		
		// Получаем email адрес из hash строки (он кодирован в base64.
		// Раскодировать будем на стороне api)
		let hash = window.location.hash;
		let email = '';
		
		if (hash != "") {
			let arr_temp = hash.split('#');
			if (arr_temp.length == 2) {
				email = arr_temp[1];
			}
		}
		
		// POST запрос для получения информации есть ли пользователь в базе
		// + возвращаем в ответе userId
		
		bridge.subscribe((e) => {
			console.log(e.detail.type);
			
			if (e.detail.type == 'VKWebAppAllowMessagesFromGroupFailed') {
				
				let post_data_delete = {
					url: window.location.search,
					email: email,
					method: 'delete_user',
				}
				
				fetch("https://ukapi.ru/vk/api.php", {
					method: 'POST',
					headers: {
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify(post_data_delete)
				})
				.then(
					() => {
						console.log('Удалено');
						this.setState({isLoaded: true, show_text: 2});
					}
				)
			}
			
			if (e.detail.type == 'VKWebAppAllowMessagesFromGroupResult') {
				
				let post_data_add = {
					url: window.location.search,
					email: email,
					method: 'add_user',
				}
				
				if (e.detail.data.result) {

					fetch("https://ukapi.ru/vk/api.php", {
						method: 'POST',
						headers: {
						  'Content-Type': 'application/json'
						},
						body: JSON.stringify(post_data_add)
					})
					.then(
						() => {
							console.log('Добавлено');
							this.setState({isLoaded: true, show_text: 1});
						}
					)
					
				}

			};
			
		});
		
		let post_data = {
			url: window.location.search,
			email: email,
			method: 'get_status',
		}
		
		// 1. Запрос по api с определением подписан ли пользователь уже (у нас в базе)
		fetch("https://ukapi.ru/vk/api.php", {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify(post_data)
		})
		.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					isLoaded: true,
					isSubscribe:result.isSubscribe,
					userId:result.userId
				});
			}
		).then(
			() => {
				// Подписывается на события, отправленные нативным клиентом
				
				// Отправляет событие нативному клиенту
				bridge.send("VKWebAppInit", {});

				if (bridge.supports("VKWebAppAllowMessagesFromGroup")) {
					bridge.send("VKWebAppAllowMessagesFromGroup", {"group_id": 88754668});
				}
				
			}
		);


		
		
		// 2. Отправляем запрос bridge.subscribe
		// - что если человек уже подписан
		// - что если человек в нашей базе подписан, а здесь нет
		
		
		

	}

	render() {
		
		if (this.state.show_text == 0) {
			return (
				<div className="data-sub">
					<div className="data-sub-img">
						<img src="/img/photo_2021-07-04_21-27-34.jpg"/>
					</div>
					<br/>
					<p>Загружаем данные ...</p>
				</div>
			);
		}
		
		if (this.state.show_text == 1) {
			return (
				<div className="data-sub">
					<div className="data-sub-img">
						<img src="/img/photo_2021-07-04_21-27-34.jpg"/>
					</div>
					<br/>
					<p>Вы активировали бот. Ожидайте сообщение с книгой от нашего сообщества.</p>
	
				</div>
			);
		}
		
		if (this.state.show_text == 2) {
			return (
				<div className="data-sub">
					<div className="data-sub-img">
						<img src="/img/photo_2021-07-04_21-27-34.jpg"/>
					</div>
					<br/>
					<p>Очень жаль :(</p>
				</div>
			);
		}
		
		
	}
}

export default App;
